<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { LMap,LMarker,LIcon, LTileLayer} from "@vue-leaflet/vue-leaflet";
import axios from 'axios';
import Swal from "sweetalert2";
import { reqMethods } from '../../../state/helpers';
import AudioPlayer from 'vue3-audio-player'
import 'vue3-audio-player/dist/style.css'
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';

export default {
  components: {
    Layout,
    PageHeader,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    AudioPlayer,
    VueFileAgent

  },
  data() {
    return {
      zoom: 12,
      maxZoom: 25,
      center: {
        lat: null,
        lng: null
      },
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      title: "Détails incident",
      items: [
        {
          text: "Incident",
          href: "/"
        },
        {
          text: "Détails",
          active: true
        }
      ],
      imgs:[],
      fichiers:[],
      odios:[],
      vdeos:[],
      personnes: [],
      vehicules: [],
      incident: {},
      images: {},
      videos: {},
      audios: {},
      files: [],
      location: [],
      position: [],
    };
  },
  methods: {
    ...reqMethods,
    detailsPerson(personne) {
      localStorage.setItem('person', JSON.stringify(personne))
      this.$router.push({path: `/DelailPerson/${personne.id}`})
    },
    detailsCar(car){
      localStorage.setItem('car', JSON.stringify(car));
      this.$router.push({ path: `/VehiculeDelail/${car.id}`});
    },
    openPDF(url) {
      window.open(url, '_blank');
    },
    getDetailsIncidents() {
      const that = this;
      const id = { id: parseInt(this.$route.params.id) };
      this.postRequest({
        route: '/investigation/findbyid/',
        data: id,
      }).then(
          function (response) {
            that.incident = response.data?.incidents[0];
            that.images = response.data?.photos;
            that.videos = response.data?.videos;
            that.audios = response.data?.audios;
            that.files = response.data?.files;
            that.personnes = response.data?.personnes[0];
            that.vehicules = response.data?.vehicules[0];
            that.position =  that.incident?.location;
          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    removeAudio(id) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cet audio? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/investigation/incident/audio/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "L'Audio à été supprimer!", "success");
            this.getDetailsIncidents();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression de l'audio",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    removePhoto(id) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette image? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/investigation/incident/image/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "L'image à été supprimer!", "success");
            this.getDetailsIncidents();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression de l'image",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    removeVideo(id) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette vidéo? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/investigation/incident/video/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "La vidéo à été supprimer!", "success");
            this.getDetailsIncidents();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression de la video",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    removeFile(id) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer ce fichier? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/investigation/incident/file/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "le fichier à été supprimer!", "success");
            this.getDetailsIncidents();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression du fichier",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    deleteThisIncident(){
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cet incident? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          const formData = new FormData();
          formData.append('id', parseInt(this.$route.params.id));
          formData.append('is_active', false);
          try {
            const response = await axios.put('https://api.alcit.sims-technologie.com/api/v1/investigation/incident/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token'),
              },
            });
            Swal.fire(response.data.message, "L'Alerte à été Supprimer!", "success");
            this.$router.push('/list-incident');
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de l'ajout de l'alerte",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    showMarkerOnMap(){
      this.location = [];
      this.location.push(this.position);
      this.center = this.position.coordinates;
      this.zoom = 12;
    },
    async addImg() {
      const formData = new FormData();
      formData.append('incident', parseInt(this.$route.params.id));
      for (const photo of this.imgs) { formData.append('image', photo.file); }
      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/investigation/incident/image/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });
        this.getDetailsIncidents();
        Swal.fire(response.data.message, "ajout réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async addAudio() {
      const formData = new FormData();
      formData.append('incident', parseInt(this.$route.params.id));
      for (const audio of this.odios) { formData.append('audio', audio.file); }
      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/investigation/incident/audio/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });
        this.getDetailsIncidents();
        Swal.fire(response.data.message, "ajout réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async addVideo() {
      const formData = new FormData();
      formData.append('incident', parseInt(this.$route.params.id));
      for (const video of this.vdeos) { formData.append('video', video.file); }
      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/investigation/incident/video/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });
        this.getDetailsIncidents();
        Swal.fire(response.data.message, "ajout réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async addFile() {
      const formData = new FormData();
      formData.append('incident', parseInt(this.$route.params.id));
      for (const file of this.fichiers) { formData.append('file', file.file); }
      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/investigation/incident/file/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });
        this.getDetailsIncidents();
        Swal.fire(response.data.message, "ajout réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
  },
  mounted() {
    this.getDetailsIncidents();
    const that = this;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      }, function (error) {
        alert("Erreur de géolocalisation : " + error.message);
      });
    } else {
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="row justify-content-between">
            <div class="col-md-6">
              <div class="btn-toolbar p-3" role="toolbar">
                <button type="button" @click="deleteThisIncident()" class="btn btn-danger me-2">
                  <i class="mdi mdi-trash-can"></i>
                  Désactiver
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" >
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Type Incident:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13"> {{ incident?.type?.name }} </strong></h5>
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Titre:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13"> {{ incident?.Titre }} </strong></h5>
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">Date:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{  incident?.date }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">Lieu:</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{  incident?.lieu }}</strong></h5>
              </div>
            </div>

             <div class="row mt-3 justify-content-between">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">Nombre de blaissés :</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{  incident?.hurt }}</strong></h5>
              </div>
            </div>

             <div class="row mt-3 justify-content-between">
              <div class="col-md-2 text-primary">
                <strong class="font-size-13">Nombre de décès :</strong>
              </div>
              <div class="col-md-10">
                <h5><strong class="font-size-13">{{  incident?.death }}</strong></h5>
              </div>
            </div>




            <div >
              <hr />
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Description:</strong>
                </div>
                <div class="col-md-10">
                  <p>{{  incident?.description }}</p>
                </div>
              </div>
            </div>
            <div>
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-4 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Audios </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="src in audios[0]" :key="src.id">
                        <div class="card">
                          <i @click="removeAudio(src.id)"
                            class="mdi mdi-close m-auto font-size-20 position-relative bg-danger"
                            style="cursor: pointer;top:33px; font-weight: 900; left: 40%; color:white;font-size: 18px; border-radius:5px;"></i>
                          <AudioPlayer :option="{
                            src: `https://api.alcit.sims-technologie.com${src.audio}`,
                            title: `Audio-${src.id}.wav`,
                            coverImage: `https://api.alcit.sims-technologie.com/media/frontutils/sound.png`,
                          }" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-6 mt-3">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'audio/*'" :uploadUrl="uploadUrl" v-model="odios" />
                        <button @click="addAudio()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div>
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-1 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);">
                      <strong>Images </strong>
                    </a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row" v-viewer>
                      <div class="col-xl-2 col-6" v-for="photo in images[0]" :key="photo.id">
                        <div class="card">
                          <i @click="removePhoto(photo.id)"
                            class="mdi mdi-close m-auto font-size-20 position-relative bg-danger"
                            style="cursor: pointer;top:33px; font-weight: 900; left: 40%; color:white;font-size: 18px; border-radius:5px;"></i>
                          <img class="card-img-top img-fluid"
                            :src="`https://api.alcit.sims-technologie.com${photo.image}`" alt="Card image cap" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-6 mt-3">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'image/*'" :uploadUrl="uploadUrl" v-model="imgs" />
                        <button @click="addImg()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div >
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-3 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Videos</strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="src in videos[0]" :key="src.id">
                        <div class="card">
                          <i @click="removeVideo(src.id)"
                            class="mdi mdi-close m-auto font-size-20 position-relative bg-danger"
                            style="cursor: pointer;top:33px; font-weight: 900; left: 40%; color:white;font-size: 18px; border-radius:5px;"></i>
                          <video class="card-img-top img-fluid"
                            :src="`https://api.alcit.sims-technologie.com${src.video}`" controls alt="Card image cap" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-6 mt-3">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'video/*'" :uploadUrl="uploadUrl" v-model="vdeos" />
                        <button @click="addVideo()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div>
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Fichers </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="src in files" :key="src.id">
                        <div class="card text-center">
                          <i @click="removeFile(src.id)"
                            class="mdi mdi-close m-auto font-size-20 position-relative bg-danger"
                            style="cursor: pointer;top:33px; font-weight: 900; left: 40%; color:white;font-size: 18px; border-radius:5px;"></i>
                          <a @click="openPDF(`https://api.alcit.sims-technologie.com${src.file}`)" href="javascript: void(0);">
                            <img class="card-img-top img-fluid" src="@/assets/images/pdf.png" alt="Card image cap" />
                          </a>
                          <strong class="font-size-13 mt-2">File-{{ src.file.split('/media/')[1] }}</strong>
                        </div>
                      </div>
                      <div class="col-xl-3 col-6 mt-3">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'application/pdf'" :uploadUrl="uploadUrl" v-model="fichiers" />
                        <button @click="addFile()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div>
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-5 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);">
                      <strong>Personnes Impliqués </strong>
                    </a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                  <b-card-body>

                    <table class="table table-nowrap table-centered mb-0 align-middle">
                      <thead class="table-light">
                      <tr>
                        <th>Nom et Prenom</th>

                        <th scope="col">Niveau dangerosité</th>
                        <th>Conduite à tenir</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="personne in personnes" :key=personne.id>
                        <td>{{ personne?.personne.nom }} {{ personne?.personne.prenom }}</td>

                        <td>{{ personne?.personne.niveau_dangerosite }}</td>
                        <td>{{ personne?.personne.conduite_a_tenir }}</td>
                         <td>{{ personne?.personne.date }}</td>

                        <td>

                           <span class="badge font-size-11 m-1" :class="{
                                                        'bg-success': `${personne?.personne.status}` === 'non_rechercher',
                                                        'bg-danger': `${personne?.personne.status}` === 'rechercher',
                                                    }">{{ personne?.personne.status }}</span>
                        </td>
                        <td>
                          <button class="btn btn-warning btn-sm me-2 w-xs" @click="detailsPerson(personne?.personne)">Details</button>
                        </td>
                      </tr>
                      </tbody>
                    </table>


                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div>
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-6 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);">
                      <strong>Engins Impliqués </strong>
                    </a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                  <b-card-body>

                    <div class="table-responsive mt-3">
                      <table class="table table-nowrap table-centered mb-0 align-middle">
                        <thead class="table-light">
                        <tr>
                          <th>Plaque véhicule</th>
                          <th>châssis</th>
                          <th>Marque</th>
                          <th>Modèle</th>
                          <th>Année</th>
                          <!-- <th scope="col">Motif</th> -->
                          <th scope="col">Type</th>
                          <th>Conduite à tenir</th>
                          <!-- <th scope="col">Date</th> -->
                          <th scope="col">Statut</th>
                          <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="vehicule in vehicules" :key=vehicule.id>
                          <td>{{ vehicule?.vehicule?.immatriculation }}</td>
                          <td>{{ vehicule?.vehicule.numero_chassis }}</td>
                          <td>{{ vehicule?.vehicule.marque }}</td>
                          <td>{{ vehicule?.vehicule.modele }}</td>
                          <td>{{ vehicule?.vehicule.annee }}</td>
                          <!-- <td>{{ vehicule.motif_recherche }}</td> -->
                          <td>{{ vehicule?.vehicule.type_vehicule }}</td>
                          <td>{{ vehicule?.vehicule.conduite_a_tenir }}</td>
                          <!-- <td>{{ vehicule.date }}</td> -->
                          <td>
                                        <span class="badge font-size-11 m-1" :class="{
                                            'bg-success': `${vehicule?.vehicule.statut}` === 'non_rechercher',
                                            'bg-danger': `${vehicule?.vehicule.statut}` === 'rechercher',
                                        }">{{ vehicule?.vehicule.statut === 'non_rechercher'? 'Retrouvé' : 'Recherché' }}</span>
                          </td>
                          <td>
                            <button class="btn btn-warning btn-sm me-2 w-xs" @click="detailsCar(vehicule?.vehicule)">Details</button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>


                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div>
              <hr />
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2">
                  <strong class="font-size-14 text-primary">Positions:</strong>
                </div>
                <div class="col-md-10">
                  <ul>
                    <li >
                      <a style="cursor: pointer;" @click="showMarkerOnMap()">position incident</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group row mt-3">
                <div class="col-lg-12">
                  <l-map :zoom="zoom" style="height: 500px;" :center="center">
                    <l-tile-layer :url="url" :maxZoom="maxZoom" />
                    <l-marker v-for="(marker, index) in location"  :key="index"
                      :visible="true"  :lat-lng="marker.coordinates">
                      <l-icon :icon-anchor="staticAnchor">
                        <img src="http://api.alcit.sims-technologie.com/media/frontutils/marker-icon.png" />
                      </l-icon>
                    </l-marker>
                  </l-map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template> 